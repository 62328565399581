export function calculateKeplerPeriod(au: number) {
  const T1 = 1; // base period
  const r1 = 1; // base semiaxis (radius)
  const r2 = au; // new semiaxis (radius)

  // calculated period based on Kepler's 3rd law
  const T2 = Math.sqrt((Math.pow(T1, 2) / Math.pow(r1, 3)) * Math.pow(r2, 3));
  return T2;
}

export function calculateKeplerRadius(period: number) {
  const T1 = 1; // base period
  const r1 = 1; // base semiaxis (radius)
  const T2 = period; // new period

  // calculate radius based on Kepler's 3rd law
  const r2 = Math.cbrt((Math.pow(T1, 2) / Math.pow(T2, 2)) * Math.pow(r1, 3));
  return r2;
}

export function calculateStarRadius(mass: number) {
  const radius = Math.pow(mass, 0.8);

  return radius;
}
