import { CapiBoundStore, ICAPI } from 'asu-sim-toolkit';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { IDnDStore, IPlanetStore } from './types';
import { ICapiModel } from '../capi';
import { Planet } from './planet-store';

export interface DraggableItem {
  id: number;
  color: string;
  planet: Planet;
}

export interface Graph {
  id: number;
  droppedItem: DraggableItem[];
}

export class DnDStore extends CapiBoundStore<ICapiModel> implements IDnDStore {
  planetStore: IPlanetStore;
  items: DraggableItem[] = [];
  bank: DraggableItem[] = [];

  constructor(capi: ICAPI<ICapiModel>, planetStore: IPlanetStore) {
    super(capi);

    this.planetStore = planetStore;

    makeObservable(this, {
      planetStore: observable,
      items: observable,
      bank: observable,
      updateBank: action.bound,
      activeGraphs: computed,
    });

    reaction(
      () => this.planetStore.planets.map((p) => p.graph.isEnabled),
      () => {
        this.initializeStore();
      }
    );

    this.dropItemInGraph = this.dropItemInGraph.bind(this);
    this.removeItemFromGraph = this.removeItemFromGraph.bind(this);
  }

  updateBank(items: DraggableItem[]) {
    this.bank = items;
  }

  getActivePlanetsWithGraphs() {
    // return this.planetStore.planets.filter((p) => p.isActive).filter((p) => p.graph.isEnabled);
    return this.planetStore.planets.filter((p) => p.graph.isEnabled);
  }

  get activeGraphs() {
    return this.getActivePlanetsWithGraphs().map((p) => p.graph);
  }

  initializeStore() {
    const colors = [
      '#228E96',
      'gray',
      '#BC630B',
      'yellow',
      'blue',
      'red',
      'orange',
      'khaki',
      'lightblue',
      'darkblue',
    ];

    const activePlanetsWithActiveGraphs = this.getActivePlanetsWithGraphs();
    const planets = this.planetStore.planets;
    const activePlanetIds = activePlanetsWithActiveGraphs.map((planet) => planet.id);

    const items = colors.map((color, idx) => ({
      id: idx,
      color: color,
      planet: planets[idx],
    }));

    this.items = items;

    const bankItems = items.filter((item) => activePlanetIds.includes(item.id));

    this.bank = bankItems;
  }

  dropItemInGraph(graphId: number, item: DraggableItem) {
    this.planetStore.planets.forEach((planet, index) => {
      if (
        planet.graph.droppedItem.length > 0 &&
        planet.graph.droppedItem[0]?.id === item.id &&
        index !== graphId
      ) {
        planet.graph.removeItem();
      }
    });

    const targetGraph = this.planetStore.planets[graphId].graph;

    if (targetGraph.droppedItem.length > 0) {
      if (item.id === targetGraph.droppedItem[0].id) return;
      this.moveItemToBank(item, targetGraph.droppedItem[0]);
      targetGraph.dropItem(item);
    } else {
      this.updateBank(this.bank.filter((it) => it.id !== item.id));
      targetGraph.dropItem(item);
    }
  }

  removeItemFromGraph(graphId: number) {
    const graph = this.planetStore.planets[graphId].graph;
    if (graph.droppedItem[0]) {
      this.updateBank([...this.bank, graph.droppedItem[0]]);
      graph.removeItem();
    }
  }

  moveItemToBank(oldItem: DraggableItem, newItem: DraggableItem) {
    const removedOldItem = this.bank.filter((item) => item.id !== oldItem.id);
    this.updateBank([...removedOldItem, newItem]);
  }
}
