import React from 'react';

import { StoreProvider } from './providers/store/store-provider';
import { ScreenSizeProvider } from './providers/screen/screen-size-provider';
import { MainView } from './components/MainView/MainView';
import { IconSheet } from './components/Icon';
import BREAKPOINTS from './breakpoints.json';
import { rootStore } from './stores';

export const App = () => {
  return (
    <ScreenSizeProvider breakpoints={BREAKPOINTS}>
      <StoreProvider store={rootStore}>
        <IconSheet />
        <MainView />
      </StoreProvider>
    </ScreenSizeProvider>
  );
};
