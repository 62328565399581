import React from 'react';
import { useDrag } from 'react-dnd';
import { observer } from 'mobx-react-lite';
import { findLabel, getPlanetSvg } from './DropZone';
import { useStores } from '../../../providers/store/use-stores';
import { DraggableItem as DraggableItemProps } from '../../../stores/dnd-store';

const DraggableItem = observer((item: DraggableItemProps) => {
  const {
    graphStore: { matchEnabled, graphLabel },
  } = useStores();

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'circle',
      item: item,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      canDrag: matchEnabled,
    }),
    [matchEnabled]
  );

  return (
    <div
      ref={matchEnabled ? drag : null}
      style={{
        opacity: isDragging ? 0 : 1,
        cursor: matchEnabled ? 'grab' : 'not-allowed',
        width: 40,
        height: 40,
      }}
    >
      {getPlanetSvg(item.id, item.color, findLabel(graphLabel, item.planet))}
    </div>
  );
});

export default DraggableItem;
