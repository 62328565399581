import { makeObservable, observable, action } from 'mobx';
import * as Tone from 'tone';
import { CapiBoundStore, ICAPI } from 'asu-sim-toolkit';
import { ISoundStore } from './types';
import { ICapiModel } from '../capi';

export class SoundStore extends CapiBoundStore<ICapiModel> implements ISoundStore {
  masterMute = true;
  private synth: Tone.PolySynth;

  constructor(capi: ICAPI<ICapiModel>) {
    super(capi);

    makeObservable(this, {
      masterMute: observable,
      toggleMute: action,
      playNote: action,
    });

    this.synth = new Tone.PolySynth().toDestination();
    Tone.Master.volume.value = -10;

    this.bindToCapi('masterMute', 'Sim.audio.mute');

    this.toggleMute = this.toggleMute.bind(this);
  }

  toggleMute() {
    this.masterMute = !this.masterMute;
  }

  playNote(frequency: string) {
    if (!this.masterMute) {
      this.synth.triggerAttackRelease(frequency, '8n');
    }
  }
}
