import { makeObservable, observable, action, reaction, computed } from 'mobx';
import { ICapiModel } from '../capi';
import { CapiBoundStore, ICAPI } from 'asu-sim-toolkit';
import { IGraphStore, IOrbitViewStore, IPlanetStore, ISoundStore, IToneMatchStore } from './types';
import { calculateKeplerPeriod } from '../utils';
import { Graph } from './graph-store';
import { CapiPlanet, planetsOrderConfig } from './domain';
import { DraggableItem } from './dnd-store';

export class Planet {
  id: number;
  name: CapiPlanet;
  color: string;
  radius: number;
  size: number;
  period: number;
  type: string;
  sound: string;
  x = 0;
  z = 0;
  soundEnabled = false;
  indicator = false;
  isActive = false;
  playDing = false;
  soundStore: ISoundStore;
  graph: Graph;

  constructor(
    id: number,
    name: CapiPlanet,
    color: string,
    radius: number,
    size: number,
    period: number,
    type: string,
    sound: string,
    soundStore: ISoundStore
  ) {
    makeObservable(this, {
      x: observable,
      z: observable,
      soundEnabled: observable,
      playDing: observable,
      isActive: observable,
      keplerPeriod: computed,
      calculatePosition: action,
    });

    this.id = id;
    this.name = name;
    this.color = color;
    this.radius = radius;
    this.size = size;
    this.period = period;
    this.type = type;
    this.sound = sound;
    this.soundStore = soundStore;
    this.graph = new Graph(this, id);

    this.calculatePosition = this.calculatePosition.bind(this);
    this.setRadius = this.setRadius.bind(this);
    this.enableGraph = this.enableGraph.bind(this);
    this.disableGraph = this.disableGraph.bind(this);
  }

  get keplerPeriod() {
    const keplerPeriod = calculateKeplerPeriod(this.radius);
    return keplerPeriod;
  }

  setRadius(radius: number) {
    this.radius = radius;
  }

  enableGraph() {
    this.graph.enable();
  }

  disableGraph() {
    this.graph.disable();
  }

  calculatePosition(elapsedTime: number) {
    const radius = this.radius * 15 + 5;
    const speedAdjustment = 1;
    const x = radius * Math.sin((elapsedTime / speedAdjustment) * (1 / this.keplerPeriod));
    const z = radius * Math.cos((elapsedTime / speedAdjustment) * (1 / this.keplerPeriod));

    this.handleDing(z, this.z);
    this.x = x;
    this.z = z;
  }

  private handleDing(newZ: number, oldZ: number) {
    const direction = newZ > oldZ ? 1 : 0;

    if (this.playDing && direction === 1) {
      this.playDing = false;
      return;
    }

    if (direction === 0 && !this.playDing) {
      if (this.soundEnabled) this.soundStore.playNote(this.sound);
      this.playDing = true;
    }
  }
}

const scaleValue = (value: number, min: number, max: number, scaleMin: number, scaleMax: number): number => {
  const normalized = (value - min) / (max - min);
  return scaleMin + normalized * (scaleMax - scaleMin);
};

const scalePlanets = (planets: Planet[], testObjectRadius: number): Planet[] => {
  const sizeMin = Math.min(...planets.map((p) => p.size));
  const sizeMax = Math.max(...planets.map((p) => p.size));

  return planets.map((planet, index) => {
    return new Planet(
      planet.id,
      planet.name,
      planet.color,
      planet.type === 'testObject' ? testObjectRadius : planetsOrderConfig[index].radius,
      scaleValue(planet.size, sizeMin, sizeMax, 0.8, 1.8),
      planet.period,
      planet.type,
      planet.sound,
      planet.soundStore
    );
  });
};

export class PlanetStore extends CapiBoundStore<ICapiModel> implements IPlanetStore {
  orbitViewStore: IOrbitViewStore;
  soundStore: ISoundStore | undefined;
  graphStore: IGraphStore;
  toneMatchStore: IToneMatchStore;
  planets: Planet[] = [];

  constructor(
    capi: ICAPI<ICapiModel>,
    orbitViewStore: IOrbitViewStore,
    soundStore: ISoundStore,
    graphStore: IGraphStore,
    toneMatchStore: IToneMatchStore
  ) {
    super(capi);

    this.orbitViewStore = orbitViewStore;
    this.graphStore = graphStore;
    this.toneMatchStore = toneMatchStore;

    makeObservable(this, {
      planets: observable,
      graphStore: observable,
    });

    this.planets = scalePlanets(
      [
        new Planet(0, CapiPlanet.ExoB, 'rgb(159, 140, 118)', 0.153, 2.56, 0.06, 'planet', 'D6', soundStore), // Kepler-344b
        new Planet(
          1,
          CapiPlanet.Mercury,
          'rgb(139, 136, 120)',
          0.383,
          0.383,
          0.241,
          'planet',
          'D4',
          soundStore
        ),
        new Planet(2, CapiPlanet.ExoC, 'rgb(200, 162, 200)', 0.488, 2.89, 0.3, 'planet', 'E6', soundStore), // Kepler-344c
        new Planet(3, CapiPlanet.Venus, 'rgb(205, 127, 50)', 0.949, 0.949, 0.615, 'planet', 'E4', soundStore),
        new Planet(4, CapiPlanet.Earth, 'rgb(70, 130, 180)', 1.0, 1.0, 1.0, 'planet', 'G4', soundStore),
        new Planet(5, CapiPlanet.Mars, 'rgb(188, 39, 50)', 0.532, 0.532, 1.88, 'planet', 'B4', soundStore),
        new Planet(
          6,
          CapiPlanet.Jupiter,
          'rgb(255, 165, 0)',
          11.21,
          11.21,
          11.86,
          'planet',
          'D5',
          soundStore
        ),
        new Planet(7, CapiPlanet.Saturn, 'rgb(250, 206, 128)', 9.45, 9.45, 29.46, 'planet', 'E5', soundStore),
        new Planet(8, CapiPlanet.Uranus, 'rgb(173, 216, 230)', 4.01, 4.01, 84.01, 'planet', 'G5', soundStore),
        new Planet(9, CapiPlanet.Neptune, 'rgb(0, 0, 255)', 3.88, 3.88, 164.79, 'planet', 'B5', soundStore),
        new Planet(10, CapiPlanet.TestObject, 'lightblue', 1.0, 1.5, 1.3, 'testObject', 'G3', soundStore),
      ],
      this.toneMatchStore.toneMatchSliderValue
    );

    reaction(
      () => this.planets.map((p) => p.graph.droppedItem),
      (itemsArray: DraggableItem[][]) => {
        const items = itemsArray[0];
        const planetsWithDroppedItem = this.planets.filter((p) => p.graph.droppedItem.length > 0);
        const planetWithCurrentDroppedItem = planetsWithDroppedItem.find((p) =>
          items.find((item) => item === p.graph.droppedItem[0])
        );
        if (planetWithCurrentDroppedItem) this.graphStore.setGraphPlanet(planetWithCurrentDroppedItem);
      }
    );

    reaction(
      () => this.orbitViewStore.planetEnabled,
      (enabledPlanets) => {
        this.planets.forEach((planet) => {
          planet.isActive = false;
        });

        enabledPlanets.forEach((planetName) => this.addPlanet(planetName));
      }
    );

    reaction(
      () => this.orbitViewStore.planetIndicator,
      (name) => {
        this.planets.forEach((planet) => {
          planet.indicator = false;
        });

        const planet = this.planets.find((p) => p.name === name);
        if (planet) {
          planet.indicator = true;
        }
      }
    );

    reaction(
      () => this.orbitViewStore.planetSoundEnabled,
      (enabledPlanets) => {
        this.planets.forEach((planet) => {
          planet.soundEnabled = false;
        });

        enabledPlanets.forEach((planetName) => {
          const planet = this.planets.find((p) => p.name === planetName);
          if (planet) {
            planet.soundEnabled = true;
          }
        });
      }
    );

    reaction(
      () => this.graphStore.graphEnabled,
      (enabledGraphs) => {
        this.planets.forEach((planet) => planet.disableGraph());

        enabledGraphs.forEach((graph) => {
          const planetName = graph.slice(5);
          const planet = this.planets.find((p) => p.name === planetName);
          if (!planet) return;
          planet.enableGraph();
        });
      }
    );

    reaction(
      () => this.toneMatchStore.toneMatchSliderValue,
      (value) => {
        const testObject = this.planets.find((p) => p.type === 'testObject');
        if (!testObject) return;

        testObject.setRadius(value);
      }
    );
  }

  private addPlanet(name: string) {
    const planetToAdd = this.planets.find((p) => p.name === name);
    if (planetToAdd) {
      planetToAdd.isActive = true;
    }
  }
}
