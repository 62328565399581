import {
  RecapStore,
  ModalStore,
  NotificationStore,
  INotificationStore,
  IModalStore,
  IRecapStore,
  IFullscreenStore,
  FullscreenStore,
  SimContextStore,
  ISimContextStore,
} from 'asu-sim-toolkit';

import { isDebugMode } from '../config';
import { capiSchema, getCapi } from '../capi';
import {
  IAppStore,
  IDnDStore,
  IGraphStore,
  IOrbitViewStore,
  IPlanetStore,
  IRootStore,
  ISoundStore,
  IToneMatchStore,
} from './types';
import { AppStore } from './app-store';
import { versionHistory } from '../version-history';
import { OrbitViewStore } from './orbit-view-store';
import { ToneMatchStore } from './tone-match-store';
import { GraphStore } from './graph-store';
import { PlanetStore } from './planet-store';
import { SoundStore } from './sound-store';
import { DnDStore } from './dnd-store';

export class RootStore implements IRootStore {
  appStore: IAppStore;
  notificationStore: INotificationStore;
  modalStore: IModalStore;
  recapStore: IRecapStore;
  fullscreenStore: IFullscreenStore;
  simContextStore: ISimContextStore;

  orbitViewStore: IOrbitViewStore;
  toneMatchStore: IToneMatchStore;
  graphStore: IGraphStore;
  soundStore: ISoundStore;
  planetStore: IPlanetStore;
  dndStore: IDnDStore;

  constructor() {
    const capi = getCapi();
    capi.init(capiSchema);
    if (isDebugMode) {
      (window as any).capi = capi;
      (window as any).store = this;
    }

    this.notificationStore = new NotificationStore();
    this.modalStore = new ModalStore();
    this.recapStore = new RecapStore(this.modalStore, versionHistory);
    this.fullscreenStore = new FullscreenStore(capi);
    this.simContextStore = new SimContextStore(capi);
    this.appStore = new AppStore(this, capi);

    this.orbitViewStore = new OrbitViewStore(capi);
    this.toneMatchStore = new ToneMatchStore(capi);
    this.soundStore = new SoundStore(capi);
    this.graphStore = new GraphStore(capi);
    this.planetStore = new PlanetStore(
      capi,
      this.orbitViewStore,
      this.soundStore,
      this.graphStore,
      this.toneMatchStore
    );
    this.dndStore = new DnDStore(capi, this.planetStore);
  }
}

export const rootStore = new RootStore();
