import { makeObservable, observable } from 'mobx';

import { ICapiModel } from '../capi';
import { CapiBoundStore, ICAPI } from 'asu-sim-toolkit';
import { IOrbitViewStore } from './types';
import { CapiPlanetArray, CapiPlanet } from './domain';

export class OrbitViewStore
  extends CapiBoundStore<ICapiModel>
  implements IOrbitViewStore
{
  planetEnabled: CapiPlanetArray = [];
  planetSoundEnabled: CapiPlanetArray = [];
  planetIndicator: CapiPlanet = CapiPlanet.TestObject;

  constructor(capi: ICAPI<ICapiModel>) {
    super(capi);

    makeObservable(this, {
      planetEnabled: observable,
      planetSoundEnabled: observable,
      planetIndicator: observable,
    });

    this.bindToCapi('planetEnabled', 'Sim.planetEnabled');
    this.bindToCapi('planetSoundEnabled', 'Sim.audioEnabled');
    this.bindToCapi('planetIndicator', 'Sim.indicator');
  }
}
