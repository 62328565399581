import React from 'react';
import LineChart from './LineChart';
import { observer } from 'mobx-react-lite';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableItem from './DraggableItem';
import DropZone from './DropZone';
import { useStores } from '../../../providers/store/use-stores';
import { CapiSimMode } from '../../../stores/domain';

const GraphMatchView = observer(() => {
  const {
    appStore: { mode },
    dndStore: { activeGraphs, bank, dropItemInGraph, removeItemFromGraph },
  } = useStores();

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-1 w-full gap-4 p-4 overflow-hidden">
        {mode === CapiSimMode.GraphMatch ? (
          <div className="flex flex-col w-[117px] h-full border-solid border-[1px] border-[#333] bg-[#191919] rounded-[8px]">
            <div className="bg-[#333] text-white h-10 flex items-center justify-center rounded-t-md">
              Bank
            </div>
            <div className="p-4 h-full justify-around flex flex-col gap-4 items-center overflow-y-auto">
              {bank.map((item) => (
                <DraggableItem key={item.id} id={item.id} color={item.color} planet={item.planet} />
              ))}
            </div>
          </div>
        ) : null}
        <div className="flex flex-1 w-full flex-col items-center text-white overflow-y-auto gap-4 box-border">
          {activeGraphs.map((graph, index) => (
            <>
              <DropZone
                key={index}
                onDrop={(item) => dropItemInGraph(graph.id, item)}
                droppedItem={graph.droppedItem}
                onRemove={() => removeItemFromGraph(graph.id)}
                name={graph.planet.name}
              >
                <LineChart planet={graph.planet} data={graph.data} />
              </DropZone>
            </>
          ))}
        </div>
      </div>
    </DndProvider>
  );
});

export default GraphMatchView;
