/* eslint-disable react/no-unknown-property */
import React, { useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { TriangleIndicator } from './Indicators';
import SVGImage from './SVGImage';
import { observer } from 'mobx-react-lite';
import { Planet } from '../../../stores/planet-store';

export const TestObject = observer(
  ({ planet: { x, z, size, calculatePosition, indicator } }: { planet: Planet }) => {
    const cubeRef = useRef<THREE.Mesh>(null);
    const materialRef = useRef<THREE.Material>(null);
    const triangleRef = useRef<THREE.Mesh>(null);

    useFrame(({ clock }) => {
      const t = clock.getElapsedTime();

      calculatePosition(t);

      if (cubeRef.current) {
        cubeRef.current.position.x = x;
        cubeRef.current.position.z = z;
      }

      if (materialRef.current) {
        materialRef.current.emissiveIntensity = 0.5 + 0.5 * Math.sin(t * 5);
      }

      if (triangleRef.current && cubeRef.current && indicator) {
        const verticalOffset = 0.5 * Math.sin(t * 3);
        triangleRef.current.position.set(
          cubeRef.current.position.x,
          cubeRef.current.position.y + 3 + verticalOffset,
          cubeRef.current.position.z
        );
      }
    });

    return (
      <>
        <mesh ref={cubeRef}>
          <boxGeometry args={[size * 3, size * 3, size * 3]} />
          <meshStandardMaterial ref={materialRef} color="lightblue" emissive="lightblue" />
        </mesh>
        {indicator ? <TriangleIndicator ref={triangleRef} /> : null}
        <SVGImage />
      </>
    );
  }
);
