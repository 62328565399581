import { action, makeObservable, observable } from 'mobx';
import { ICapiModel } from '../capi';
import { IAppStore, IRootStore } from './types';
import { CapiBoundStore, ICAPI } from 'asu-sim-toolkit';
import { CapiSimMode } from './domain';

export class AppStore extends CapiBoundStore<ICapiModel> implements IAppStore {
  private readonly rootStore: IRootStore;
  mode = CapiSimMode.ToneMatch;
  starMassEnabled = false;
  starMassValue = 1;

  constructor(rootStore: IRootStore, capi: ICAPI<ICapiModel>) {
    super(capi);

    this.rootStore = rootStore;

    makeObservable(this, {
      mode: observable,
      starMassEnabled: observable,
      starMassValue: observable,
      setStarMassValue: action.bound,
    });

    this.bindToCapi('mode', 'Sim.mode');

    this.bindToCapi('starMassEnabled', 'Sim.starMass.enabled');
    this.bindToCapi('starMassValue', 'Sim.starMass.value');
  }
  setStarMassValue(value: number): void {
    this.starMassValue = value;
  }
}
