import { action, makeObservable, observable } from 'mobx';

import { ICapiModel } from '../capi';
import { CapiBoundStore, ControlStore, ICAPI, IControlState } from 'asu-sim-toolkit';
import { IToneMatchStore } from './types';

export class ToneMatchStore extends CapiBoundStore<ICapiModel> implements IToneMatchStore {
  toneMatchSlider: IControlState;
  toneMatchSliderMoveCount = 0;
  toneMatchSliderValue = 1.3;

  constructor(capi: ICAPI<ICapiModel>) {
    super(capi);

    makeObservable(this, {
      toneMatchSliderMoveCount: observable,
      toneMatchSliderValue: observable,
      setToneMatchSliderValue: action.bound,
      incrementToneMatchSliderMoveCount: action.bound,
    });

    this.toneMatchSlider = new ControlStore(
      capi,
      'Sim.toneMatchSlider.enabled',
      'Sim.toneMatchSlider.visible'
    );
    this.bindToCapi('toneMatchSliderMoveCount', 'Sim.toneMatchSlider.moveCount');
    this.bindToCapi('toneMatchSliderValue', 'Sim.toneMatchSlider.value');
  }

  setToneMatchSliderValue(value: number) {
    this.toneMatchSliderValue = value;
  }

  incrementToneMatchSliderMoveCount() {
    this.toneMatchSliderMoveCount += 1;
  }
}
