import React, { useMemo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DraggableItem } from '../../../stores/dnd-store';
import { CapiPlanet, CapiPlanetGraphArray, CapiSimMode } from '../../../stores/domain';
import { useStores } from '../../../providers/store/use-stores';
import { observer } from 'mobx-react-lite';
import { Planet } from '../../../stores/planet-store';

interface DropZoneProps {
  droppedItem: DraggableItem[];
  children: React.ReactElement;
  name: CapiPlanet;
  onDrop: (item: DraggableItem) => void;
  onRemove: () => void;
}

export const getPlanetSvg = (id: number, color: string, showLabel: boolean) => {
  const svgStyle = {
    stroke: color,
    strokeWidth: 3,
    fill: 'white',
  };

  const textStyle: React.CSSProperties = {
    fontSize: '20px',
    fill: color,
    textAnchor: 'middle',
    dominantBaseline: 'middle',
  };

  switch (id) {
    case 0:
      return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="18" style={svgStyle} />
          {showLabel ? (
            <svg width="36" height="36" viewBox="-10 -8 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.4118 20C4.67647 19.2174 2 14.913 2 11C2 7.08696 4.29412 2 9.64706 2C13.4706 2 15 5.13043 15 7.08696C15 9.04348 13.4706 11 10.7941 11C9.26471 11 7.73529 10.6087 7.35294 8.65217C7.053 7.11735 8.88235 5.13043 10.7941 5.91304"
                stroke="#228E96"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
          ) : null}
          {/* ExoA */}
        </svg>
      );
    case 1:
      return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="18" style={svgStyle} />
          <text x="20" y="22" style={textStyle}>
            {showLabel ? '☿' : null}
          </text>{' '}
          {/* Mercury */}
        </svg>
      );
    case 2:
      return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="18" style={svgStyle} />
          {showLabel ? (
            <svg width="36" height="36" viewBox="-10 -8 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.85388 4.63149C8.72795 3.84448 9.73849 3.33026 10.8855 3.08883C12.0174 2.86097 13.1487 2.92026 14.2793 3.26669C15.3947 3.62669 16.3528 4.25126 17.1534 5.14039C17.954 6.02953 18.4614 7.03253 18.6757 8.1494C18.8885 9.29492 18.8224 10.4186 18.4775 11.5206C18.1175 12.636 17.5004 13.5873 16.6264 14.3743C16.1592 14.795 15.6603 15.135 15.1298 15.3943C14.6129 15.6688 14.0432 15.8543 13.4207 15.9509L13.4371 18.5149L17.1968 17.4627L12.0024 23.2858L6.80994 17.5645L10.5702 18.5585L10.5402 15.843C9.92966 15.7105 9.35307 15.4793 8.81036 15.1493C8.28122 14.8344 7.79955 14.4358 7.36533 13.9536C6.56475 13.0644 6.05128 12.0396 5.82492 10.879C5.5835 9.73199 5.64279 8.60074 6.00278 7.48525C6.34771 6.38332 6.96475 5.43207 7.85388 4.63149ZM9.68572 6.66595C9.15827 7.14088 8.79136 7.70318 8.58501 8.35288C8.37865 9.00258 8.34413 9.66128 8.48144 10.329C8.60369 11.0103 8.90227 11.6146 9.37719 12.1421C9.83855 12.6545 10.3948 12.9995 11.046 13.1772C11.6821 13.3685 12.3341 13.3955 13.0018 13.2582C13.6695 13.1209 14.2671 12.8148 14.7945 12.3399C15.3069 11.8785 15.6663 11.323 15.8726 10.6733C16.079 10.0236 16.1278 9.36562 16.0192 8.69941C15.8954 8.04677 15.6029 7.46426 15.1415 6.95188C14.6666 6.42442 14.1043 6.05752 13.4546 5.85116C12.7898 5.65837 12.1236 5.63064 11.4559 5.76795C10.7882 5.90527 10.1981 6.2046 9.68572 6.66595Z"
                fill="#BC630B"
              />
              <path d="M5 26H19" stroke="#BC630B" strokeWidth="3" />
            </svg>
          ) : null}
          {/* ExoA */}
        </svg>
      );
    case 3:
      return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="18" style={svgStyle} />
          <text x="20" y="22" style={textStyle}>
            {showLabel ? '♀' : null}
          </text>{' '}
          {/* Venus */}
        </svg>
      );
    case 4:
      return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="18" style={svgStyle} />
          <text x="20" y="22" style={textStyle}>
            {showLabel ? '♁' : null}
          </text>{' '}
          {/* Earth */}
        </svg>
      );
    case 5:
      return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="18" style={svgStyle} />
          <text x="20" y="22" style={textStyle}>
            {showLabel ? '♂' : null}
          </text>{' '}
          {/* Mars */}
        </svg>
      );
    case 6:
      return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="18" style={svgStyle} />
          <text x="20" y="22" style={textStyle}>
            {showLabel ? '♃' : null}
          </text>{' '}
          {/* Jupiter */}
        </svg>
      );
    case 7:
      return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="18" style={svgStyle} />
          <text x="20" y="22" style={textStyle}>
            {showLabel ? '♄' : null}
          </text>{' '}
          {/* Saturn */}
        </svg>
      );
    case 8:
      return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="18" style={svgStyle} />
          <text x="20" y="22" style={textStyle}>
            {showLabel ? '♅' : null}
          </text>{' '}
          {/* Uranus */}
        </svg>
      );
    case 9:
      return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="18" style={svgStyle} />
          <text x="20" y="22" style={textStyle}>
            {showLabel ? '♆' : null}
          </text>{' '}
          {/* Neptune */}
        </svg>
      );
    default:
      return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="18" style={svgStyle} />
          <text x="20" y="22" style={textStyle}>
            {showLabel ? '?' : null}
          </text>{' '}
          {/* Default */}
        </svg>
      );
  }
};

export const findLabel = (graphLabel: CapiPlanetGraphArray, planet: Planet) => {
  return Boolean(
    graphLabel.find((label) => {
      const labelPlanet = label.slice(5);
      return labelPlanet === planet.name;
    })
  );
};

const DropZone = observer(({ onDrop, droppedItem, name, children, onRemove }: DropZoneProps) => {
  const {
    appStore: { mode },
    graphStore: { graphLabel },
    dndStore: { items },
  } = useStores();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'circle',
    drop: (item: DraggableItem) => onDrop(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'circle',
      item: droppedItem.length > 0 ? droppedItem[0] : null,
      canDrag: droppedItem.length > 0,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      end: (_, monitor) => {
        if (!monitor.didDrop()) {
          onRemove();
        }
      },
    }),
    [droppedItem]
  );

  const getPlanetColor: string = useMemo(() => {
    if (mode === CapiSimMode.GraphMatch) return '#333';

    const item = items.find((item) => {
      return item.planet.name === name;
    });

    return item?.color || '#333';
  }, [mode, items, name]);

  return (
    <div
      ref={drop}
      className="flex w-full border-solid border-[1px] bg-black rounded-[8px]"
      style={{
        borderColor: isOver ? '#FFD585' : getPlanetColor,
      }}
    >
      {mode === CapiSimMode.GraphMatch ? (
        <div
          className="flex relative items-center justify-center w-[60px] h-full rounded-l-[8px] border-r-[1px] border-[#333]"
          style={{
            backgroundColor: isOver ? 'rgba(255, 213, 133, 0.4)' : 'rgba(51, 51, 51, 0.4)',
            borderColor: isOver ? '#FFD585' : getPlanetColor,
          }}
        >
          <div
            ref={droppedItem.length > 0 ? drag : null}
            className="absolute border-[1px] border-[#333] rounded-[50%] t-[50%] l-[50%] w-10 h-10"
            style={{
              borderColor: isOver ? '#FFD585' : getPlanetColor,
              cursor: droppedItem.length > 0 ? 'grab' : 'default',
            }}
          />
          {droppedItem.length > 0 && (
            <div style={{ opacity: isDragging ? 0 : 1 }} key={String(graphLabel)}>
              {getPlanetSvg(
                droppedItem[0].id,
                droppedItem[0].color,
                findLabel(graphLabel, droppedItem[0].planet)
              )}
            </div>
          )}
        </div>
      ) : null}
      {children}
    </div>
  );
});

export default DropZone;
