/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Canvas } from '@react-three/fiber';
import { Camera } from './Camera';
import { Star } from './Star';
import { PlanetComponent } from './Planet';
import { useStores } from '../../../providers/store/use-stores';
import { observer } from 'mobx-react-lite';
import { TestObject } from './TestObject';

const OrbitView = observer(() => {
  const {
    soundStore: { masterMute, toggleMute },
    planetStore: { planets },
  } = useStores();

  const handleMuteClick = () => {
    toggleMute();
  };

  return (
    <div className="h-52 flex w-full border-b-2">
      <button
        onClick={handleMuteClick}
        className={`z-10 absolute left-md top-md cursor-pointer transition-colors duration-300 ease-in-out ${
          masterMute ? 'opacity-50' : 'opacity-100'
        }`}
      >
        <img
          src="assets/muteIcon.svg"
          alt="mute"
          className={`transition-opacity duration-300 ease-in-out ${masterMute ? 'opacity-50' : ''}`}
        />
      </button>

      <Canvas>
        <Camera />
        <Star />
        {planets.map((planet) =>
          planet.isActive && planet.type === 'planet' ? (
            <PlanetComponent planet={planet} key={planet.id} />
          ) : null
        )}
        {planets.map((planet) =>
          planet.isActive && planet.type === 'testObject' ? <TestObject planet={planet} /> : null
        )}
        <ambientLight intensity={1} />
      </Canvas>
    </div>
  );
});

export default OrbitView;
