export enum CapiSimMode {
  ToneMatch = 'toneMatch',
  GraphMatch = 'graphMatch',
  MeasurePeriod = 'measurePeriod',
  StarMass = 'starMass',
}

export enum CapiPlanet {
  None = 'None',
  Mercury = 'Mercury',
  Venus = 'Venus',
  Earth = 'Earth',
  Mars = 'Mars',
  Jupiter = 'Jupiter',
  Saturn = 'Saturn',
  Uranus = 'Uranus',
  Neptune = 'Neptune',
  ExoB = 'ExoB',
  ExoC = 'ExoC',
  TestObject = 'TestObject',
}

export enum CapiPlanetGraph {
  GraphMercury = 'graphMercury',
  GraphVenus = 'graphVenus',
  GraphEarth = 'graphEarth',
  GraphMars = 'graphMars',
  GraphJupiter = 'graphJupiter',
  GraphSaturn = 'graphSaturn',
  GraphUranus = 'graphUranus',
  GraphNeptune = 'graphNeptune',
  GraphExoB = 'graphExoB',
  GraphExoC = 'graphExoC',
}

export type CapiPlanetGraphArray = (keyof typeof CapiPlanetGraph)[];
export type CapiPlanetArray = (keyof typeof CapiPlanet)[];

export const TONE_MATCH = {
  id: 'toneMatch',
  min: 0,
  max: 2,
  step: 0.1,
  defaultValue: 1.3,
};

export const MEASURE_PERIOD = {
  id: 'measurePeriod',
  min: 0.1,
  max: 165,
  step: 0.1,
  defaultValue: 1,
};

export const STAR_MASS = {
  id: 'starMass',
  min: 0.1,
  max: 25,
  step: 0.1,
  defaultValue: 1,
};

export const planetsOrderConfig = [
  { radius: 0.3, sound: 'D4' },
  { radius: 0.5, sound: 'E4' },
  { radius: 0.6, sound: 'G4' },
  { radius: 0.8, sound: 'B4' },
  { radius: 1.0, sound: 'D5' },
  { radius: 1.2, sound: 'E5' },
  { radius: 1.6, sound: 'G5' },
  { radius: 1.7, sound: 'B5' },
  { radius: 1.8, sound: 'D6' },
  { radius: 1.9, sound: 'E6' },
  { radius: 2.0, sound: 'G3' },
];
