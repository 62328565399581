import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../providers/store/use-stores';
import { Planet } from '../../../stores/planet-store';

const GLOBAL_SCALE_FACTOR = 1.15;
const BASE_WIDTH = 1920;
const BASE_HEIGHT = 400;
const SVG_WIDTH = BASE_WIDTH * GLOBAL_SCALE_FACTOR;
const SVG_HEIGHT = BASE_HEIGHT * GLOBAL_SCALE_FACTOR;
const CENTER_X = SVG_WIDTH / 2;
const CENTER_Y = SVG_HEIGHT / 2;
const STAR_SIZE_SCALE = 1 * GLOBAL_SCALE_FACTOR;
const SLIDER_WIDTH = 150 * GLOBAL_SCALE_FACTOR;
const STAR_RADIUS = 20;

const TopView = observer(() => {
  return (
    <div className="flex flex-1 w-full justify-center items-center">
      <div className="flex flex-col items-center">
        <svg width={SVG_WIDTH} height={SVG_HEIGHT} style={{ position: 'relative' }}>
          <circle cx={CENTER_X} cy={CENTER_Y} r={STAR_RADIUS * STAR_SIZE_SCALE} fill="yellow" />

          <circle cx={CENTER_X} cy={CENTER_Y} r={STAR_RADIUS * STAR_SIZE_SCALE * 3} fill="url(#starGlow)" />
          <defs>
            <radialGradient id="starGlow">
              <stop offset="0.18" stopColor="#FEDA88" />
              <stop offset="1" stopColor="#BD3C0B" stopOpacity="0" />
            </radialGradient>
          </defs>

          <TestObject />
        </svg>
      </div>
    </div>
  );
});

const TestObject = observer(() => {
  const {
    toneMatchStore: {
      toneMatchSlider,
      incrementToneMatchSliderMoveCount,
      toneMatchSliderValue,
      setToneMatchSliderValue,
    },
    planetStore: { planets },
  } = useStores();

  const { radius, x, z, isActive } = planets.find((p) => p.type === 'testObject') as Planet;
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleDistanceChange = (value: number) => {
    setToneMatchSliderValue(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      incrementToneMatchSliderMoveCount();
    }, 1000);
  };

  const radiusMap: { [key: number]: number } = {
    0: 0.8 * GLOBAL_SCALE_FACTOR,
    1: 1.1 * GLOBAL_SCALE_FACTOR,
    2: 1.2 * GLOBAL_SCALE_FACTOR,
  };

  const eclipticMap: { [key: number]: number } = {
    0: 16 * GLOBAL_SCALE_FACTOR,
    1: 88 * GLOBAL_SCALE_FACTOR,
    2: 160 * GLOBAL_SCALE_FACTOR,
  };

  const getRadiusForAU = (au: number, ecliptic?: boolean): number => {
    if (au <= 0) return ecliptic ? eclipticMap[0] : radiusMap[0];
    if (au >= 2) return ecliptic ? eclipticMap[2] : radiusMap[2];

    const lowerAU = Math.floor(au);
    const upperAU = Math.ceil(au);
    let lowerRadius;
    let upperRadius;

    if (ecliptic) {
      lowerRadius = eclipticMap[lowerAU];
      upperRadius = eclipticMap[upperAU];
    } else {
      lowerRadius = radiusMap[lowerAU];
      upperRadius = radiusMap[upperAU];
    }

    return lowerRadius + (upperRadius - lowerRadius) * (au - lowerAU);
  };

  const eclipticRadius = getRadiusForAU(radius, true);
  const px = getRadiusForAU(radius);
  const planetX = CENTER_X + (px + 4) * x;
  const planetY = CENTER_Y + (px + 4) * z;

  return (
    <>
      <ellipse
        cx={CENTER_X}
        cy={CENTER_Y}
        rx={eclipticRadius + 4}
        ry={eclipticRadius + 4}
        stroke="grey"
        fill="none"
        strokeDasharray={10}
      />
      {isActive ? (
        <rect
          x={planetX - 7 * GLOBAL_SCALE_FACTOR}
          y={planetY - 7 * GLOBAL_SCALE_FACTOR}
          width={14 * GLOBAL_SCALE_FACTOR}
          height={14 * GLOBAL_SCALE_FACTOR}
          fill="lightblue"
        />
      ) : null}
      <foreignObject x={CENTER_X - 15} y={CENTER_Y + eclipticRadius + 4} width="30" height="40">
        <img src="/assets/soundIndicator.svg" />
      </foreignObject>
      {toneMatchSlider.isVisible && (
        <foreignObject
          x={CENTER_X + 20 * GLOBAL_SCALE_FACTOR}
          y={CENTER_Y - 16}
          width={SLIDER_WIDTH + 80}
          height="32"
        >
          <div className="flex items-center justify-between">
            <input
              type="range"
              min="0.1"
              max="2"
              step={0.1}
              value={toneMatchSliderValue}
              onChange={(e) => handleDistanceChange(parseFloat(e.target.value))}
              style={{
                width: SLIDER_WIDTH,
              }}
              className="position-relative h-1 bg-gray-200 rounded-lg cursor-pointer appearance-none"
              disabled={!toneMatchSlider.isEnabled}
            />
            <input
              type="number"
              min="0.1"
              max="2"
              step={0.1}
              value={toneMatchSliderValue}
              onChange={(e) => handleDistanceChange(parseFloat(e.target.value))}
              className="w-12 h-8 px-2 border border-gray-300 rounded-lg"
              disabled={!toneMatchSlider.isEnabled}
            />
            <span className="text-white">au</span>
          </div>
        </foreignObject>
      )}
    </>
  );
});

export default TopView;
