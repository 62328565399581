/* eslint-disable react/no-unknown-property */
import React from 'react';
import * as THREE from 'three';

export const TriangleIndicator = React.forwardRef((_, ref) => {
  const vertices = new Float32Array([0, -1, 0, -1, 1, 0, 1, 1, 0]);

  const triangleGeometry = new THREE.BufferGeometry();
  triangleGeometry.setAttribute(
    'position',
    new THREE.BufferAttribute(vertices, 3)
  );

  return (
    <mesh ref={ref} geometry={triangleGeometry}>
      <meshBasicMaterial color="red" side={THREE.DoubleSide} />
    </mesh>
  );
});
TriangleIndicator.displayName = 'Triangle Indicator';
